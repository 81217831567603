import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Nodata, NodataSubTitle, NodataDesc, NodataContainer } from "../components/Nodata"
import SearchCard from "../components/SearchCard"
import SearchBox from "../components/SearchBox"
import { useSearch } from "../utils/useSearch"

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 120px 0px 30px;
  @media (min-width:992px) {
    padding: 160px 0px 60px;
  }
  @media (min-width:1200px) {
    padding: 200px 0px 90px;
  }
  & h1 {
    text-align: center;
    text-transform: uppercase;
  }
`
const SearchList = styled.div`
  margin:0;
`
const SearchItem = styled.div`
  .media-card{
    .media-card-thumb{
      position: relative;
      width: 100%;
      min-height: 1px;
      padding:0 15px;
      @media (min-width:576px) {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
      @media (min-width:1200px) {
        flex: 0 0 30%;
        max-width: 30%;
      }
    }
    .media-card-body{
      width: 100%;
      min-height: 1px;
      padding:15px 15px 0 15px;
      @media (min-width:576px) {
        padding:0 15px;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
      }
      @media (min-width:1200px) {
        flex: 0 0 70%;
        max-width: 70%;
      }
    }
  }
`

const SearchForm = styled.div`
  background-color: #EEF8FF;
  border-radius: 6px;
  width: 100%;
  max-width: 360px;
  margin:0 auto 30px auto;
  @media (min-width:1200px) {
    margin:0 auto 40px auto;
  }
  @media (min-width:1600px) {
    margin:0 auto 60px auto;
  }
  .input-icon{
    > .form-control{
      padding: 8px 15px;
      @media (min-width: 1200px){
          padding: 10px 15px;
        }
        @media (min-width: 1600px){
          padding: 15px;
        }
        &::placeholder {
          color:#004485;
        }
        &:focus,
        &:active {
          &::placeholder {
            color:#004485;
          }
        }
    }
    .icon {
        width:45px;
        > svg{
          fill:#062c44;
          width:20px;
        }    
    }
    &.right {
        > .form-control{
            padding-right: 45px;
            padding-left: 15px;
        }
    }
  }
`

const SearchPage = ({ data, location }) => {
  const [query, setQuery] = useState(location?.state?.searchKey)
  // const index = data.localSearchPages.index
  // const store = data.localSearchPages.store
  const results = useSearch(query);

  // const results = useFlexSearch(query, index, store)

  const renderSearchResult = () => {
    if (!query) return null;

    if (!results || results.length === 0) {
      return (
        <Nodata>
          <NodataContainer>
            <NodataSubTitle className='h3'>No Results Found</NodataSubTitle>
            <NodataDesc><p>The page you requested could not be found. Try refining your search, or use the navigation above to locate the post.</p></NodataDesc>
          </NodataContainer>
        </Nodata>
      )
    }

    return (results.map((item, i) =>
      <SearchItem key={i}><SearchCard data={item} /></SearchItem>
    ))
  }

  return (
    <Layout hideSearch>
      <SEO title="Search" />
      <Wrapper>
        <div className="container">
          <h1> Search Result</h1>
          <SearchForm className='search-form'>
            <SearchBox setQuery={setQuery} searchKey={query} />
          </SearchForm>
          <SearchList className="search-list">
            {renderSearchResult()}
          </SearchList>
        </div>
      </Wrapper>
    </Layout>
  )
}

export default SearchPage

// export const pageQuery = graphql`
//   query SearchPageQuery{
//     localSearchPages {
//       index
//       store
//     }
//   }
// `